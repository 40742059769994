import React from 'react'

const Image = ({src,title,classes}) => (
  <img
    src={`/assets/images/${src}`}
    alt={title}
    className={classes}
  />
)

export default Image
