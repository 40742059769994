import React, { Component } from 'react'
import History from '../../common/history'
import Data from '../../data/instantWin'
import Loading from '../../pages/instantWin/loading'

class InstantWinHome extends Component {

    componentDidMount() {

      window.scroll(0, 0)

      const urlParams = new URLSearchParams(window.location.search)
      const uid = urlParams.has('uid') ? urlParams.get('uid') : null

      setTimeout(f => {
        History.push(`/instant-win/form?uid=${uid}`)
      },2300)

    }

    render() {

      return (
        <Loading
          data={Data.loading}
        />
      )

    }

}

export default InstantWinHome
