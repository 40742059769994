import React from 'react'
import NestgateLogo from '../assets/images/nestgate_logo.svg'
import GCLLogo from '../assets/images/guala_logo.svg'

const Footer = () => (
    <footer className="footer center-text">
      <img className="NestgateLogo" src={NestgateLogo} alt="Nestgate" />
      Powered by
      <img className="GCLLogo" src={GCLLogo} alt="GCL" />
      &copy; Guala Closures. All Rights Reserved 2019
    </footer>
)

export default Footer
