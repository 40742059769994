import React from 'react'
import ReactCodeInput from 'react-code-input'

const PinInput = ({data,onChange,isValid,isError}) => (
  <>
    <p
      className="small-text center-text color1">
      {data.instructions}
    </p>
    <ReactCodeInput
      autoFocus={false}
      id="pin-input"
      length={4}
      type="text"
      inputMode="numeric"
      onChange={onChange}
    />
    {
      isError && (
        <p className="error center-text">
          {data.invalid}
        </p>
      )
    }
    <button
      disabled={!isValid}
      type="submit"
      className="btn btn-primary">
      {data.label}
    </button>
  </>
)

export default PinInput
