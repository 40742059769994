import React from 'react'
import Logo from '../../common/logo'
import Bottle from '../../common/bottle'
import Progress from '../../components/progress'

const InstantWinLoading = ({data}) => (
  <>
    <section className="fullscreen gradient-bg instant-win-loader">
      <Logo size="lg" classes="animated fadeIn" />
      <Bottle classes="instant-win-bottle-img bottle-img-overlay animated fadeInUp" />
      <div className="intro-text-wrapper center-text text-box animated fadeIn">
        <div className="intro-text">
          <h3 className="instant-win-title white-text animated fadeIn">
            {data.title}
          </h3>
          <h1 className="color1-text animated fadeInUp" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          <Progress
            delay={1000}
            variant="sm" />
        </div>
      </div>
    </section>
  </>
)

export default InstantWinLoading
