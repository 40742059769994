import React from 'react'
import Logo from '../common/logo'
import Bottle from '../common/bottle'

const InstantWinHeader = ({data,showBottle,classes}) => (
  <>
    <Logo size="sm" classes="animated fadeIn" />
    <div className="instant-win-header">
      <div className="instant-win-header-bg" />
      {
          showBottle && (
            <Bottle classes="instant-win-header-bottle-img animated fadeInUp" />
          )
      }
      <div className={`animated fadeIn delay-1s ${classes}`}>
        {
          data.title !== undefined && (
            <h3 className="instant-win-title white-text" dangerouslySetInnerHTML={{ __html: data.title }} />
          )
        }
        {
          data.subtitle !== undefined && (
            <h2 className="white-text" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          )
        }
        {
          data.standfirst !== undefined && (
            <p className="white-text small-text" dangerouslySetInnerHTML={{ __html: data.standfirst }} />
          )
        }
      </div>
    </div>
  </>
)

export default InstantWinHeader
