import React from 'react'

const Badge = ({points,variant}) => (
  <div className={`badge badge-${variant}`}>
    <h5 className="white-text">
      {points}
    </h5>
  </div>
)

export default Badge
