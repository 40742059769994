import React from 'react'

const Avatar = ({variant,classes}) => (
  <div className={`avatar avatar-${variant} ${classes}`}>
    <svg>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-286.000000, -23.000000)" fill="#4E6D7C">
                <g transform="translate(286.000000, 23.000000)">
                    <path d="M18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 Z M18.0000088,19.1243308 C14.6899665,19.1243308 11.9441026,21.1059665 11.4293464,23.7018371 C11.3388582,24.1580454 11.6771743,24.5853659 12.1311673,24.5853659 L12.1311673,24.5853659 L23.8687906,24.5853659 C24.3227836,24.5853659 24.6610997,24.1580454 24.5706712,23.7018371 C24.055915,21.1059665 21.3100511,19.1243308 18.0000088,19.1243308 Z M18.000059,9.65853659 C15.7774523,9.65853659 13.9756098,11.5330307 13.9756098,13.8453302 C13.9756098,16.1576296 15.7774523,18.0321237 18.000059,18.0321237 C20.2226067,18.0321237 22.0243902,16.1576296 22.0243902,13.8453302 C22.0243902,11.5330307 20.2226067,9.65853659 18.000059,9.65853659 Z"></path>
                </g>
            </g>
        </g>
    </svg>
  </div>
)

export default Avatar
