import React from 'react'
import { Link } from 'react-router-dom'
import CardFooter from "../components/cardFooter"
import Badge from "../components/badge"

const Card = ({title,subtitle,slug,image,points,redeemed,index,uid,onClick}) => (
  <Link
    to={{
      pathname: `experience/${slug}`,
      search: uid
    }}
    style={{backgroundImage: `url(/assets/images/${image})`, animationDelay: `${index*200}ms`}}
    className="card animated fadeInRight">
    {
      redeemed ? (
        <Badge
          points="Redeemed"
          variant="overlay" />
      ) : (
        <Badge
          points={`${points} PTS`}
          variant="overlay" />
      )
    }
    <CardFooter
      title={title}
      subtitle={subtitle}
      />
  </Link>
)

export default Card
