import React from 'react'
import Fade from 'react-reveal/Fade'

const Progress = ({delay,variant}) => (
  <Fade delay={delay}>
    <div className={`progress progress-${variant}`}>
      <svg>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
              <g transform="translate(-175.000000, -417.000000)" stroke="#FFFFFF" strokeWidth="2">
                  <path className="path" d="M188,442 C191.438287,442 194.538835,440.553965 196.726662,438.236876 C198.755947,436.087696 200,433.18913 200,430 C200,423.372583 194.627417,418 188,418 C181.372583,418 176,423.372583 176,430 C176,433.588042 177.574743,436.808284 180.070798,439.007293"></path>
              </g>
          </g>
      </svg>
    </div>
  </Fade>

)

export default Progress
