import React, { Component } from 'react'
import Data from '../../data/instantWin'
import Footer from "../../common/footer"
import InstantWinCheck from "../../components/instantWinCheck"
import InstantWinHeader from "../../components/instantWinHeader"

class InstantWinWin extends Component {

    state = {
      isLoaded: false,
      isValid: false,
      isConfirmation: false,
      emailAddress: "",
      isSubmitted: false
    }

    componentDidMount() {

      window.scroll(0,0)

      setTimeout(f => {
        this.setState({
          isLoaded: true
        })
      },2000)

    }

    handleChange(val) {
      const email = val.target.value
      const valid = email.length >= 1 ? true : false

      this.setState({
        emailAddress: email,
        isValid: valid
      })
    }

    handleFormSubmit(e) {
      e.preventDefault();
      this.setState({
        isSubmitted: true,
        isConfirmation: true,
        emailAddress: ""
      })

      setTimeout(f => {
        window.scroll(0,0)
      },300)

    }

    render() {

      const { isLoaded, isValid, isConfirmation, emailAddress } = this.state

      return (
        <>
          {
            !isLoaded ? (
              <section className="fullscreen">
                <InstantWinCheck
                  data={Data.check} />
              </section>
            ) : (
              <>
                <section className="fullscreen">
                  <InstantWinHeader
                    data={Data.win}
                    classes="instant-win-text-box win-text-box center-text" />
                  <div className="container">
                    {
                      isConfirmation ? (
                        <div
                          className="center-text animated fadeInUp delay-1s"
                          dangerouslySetInnerHTML={{ __html: Data.win.notClaimed.text }} />
                      ) : (
                        <>
                          <div
                            className="center-text animated fadeInUp delay-1s"
                            dangerouslySetInnerHTML={{ __html: Data.win.claimed.text }} />
                          <form
                            id="form"
                            className="instant-win-form animated fadeIn delay-2s"
                            onSubmit={this.handleFormSubmit.bind(this)}>
                            <input
                              required
                              autoFocus
                              autoComplete="false"
                              type="text"
                              name="Email Address"
                              className="field"
                              placeholder={Data.win.claimed.placeholder}
                              onChange={this.handleChange.bind(this)}
                              value={emailAddress} />
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!isValid}>
                              {Data.win.claimed.label}
                            </button>
                          </form>
                        </>
                      )
                    }
                  </div>
                </section>
                <Footer />
              </>
            )
          }
        </>
      )

    }

}

export default InstantWinWin
