import React from 'react'
import { Link } from 'react-router-dom'

const BackBtn = ({uid}) => (
  <Link
    to={{
      pathname: "../experiences",
      search: uid,
      state: { ref: "back" }
    }}
    className="back-btn">
    {uid}
  </Link>
)

export default BackBtn
