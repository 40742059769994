import React from 'react'
import Logo from '../common/logo'

const Holding = () => (
  <section className="fullscreen gradient-bg vertical-align">
    <Logo
      size="md" />
  </section>
)

export default Holding
