import React from 'react'

const TimelineLabel = ({icon,label}) => (
  <>
    <i className={`icon timeline-icon ${icon}-icon`} />
    <span className="timeline-label">
      {label}
    </span>
  </>
)

export default TimelineLabel
