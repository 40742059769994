import React, { Component } from "react"
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react'

export class MapContainer extends Component {

  render() {

    const {coordinates,zoom} = this.props

    return (
      <Map
        google={this.props.google}
        zoom={zoom}
        disableDefaultUI={true}
        initialCenter={{
          lat: coordinates[0][0],
          lng: coordinates[0][1]
        }}
      >
        {
          coordinates.map((p,i) =>
            <Marker
              key={i}
              position={{lat: p[0], lng: p[1]}}
              icon={{
                url: "/assets/images/pin_filled.svg",
              }} />
        )}
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA0Fbgcnr-2n0X8kDb6mGxBLHQ2pYy3458"
})(MapContainer)
