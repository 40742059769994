import React, { Component } from 'react'
import Data from '../../data/provenance'
import Footer from '../../common/footer'
import Loading from '../../pages/provenance/loading'
import Timeline from '../../pages/provenance/timeline'

class ProvenanceHome extends Component {

    state = {
      isLoaded: false
    }

    componentDidMount() {

      window.scroll(0,0)

      setTimeout(f => {
        this.setState({isLoaded: true})
      },1800)

    }

    render() {

      const { isLoaded } = this.state

      return (
        <>
          <Loading
            data={Data.loading}
          />
          { isLoaded && (
            <>
              <Timeline
                data={Data.timeline}
              />
              <Footer />
            </>
          )}
        </>
      )
    }
}

export default ProvenanceHome
