import React from 'react'
import ProgressBlue from '../components/progressBlue'

const ModalProcessing = ({data,onClose}) => (
  <div className="modal-wrapper">
    <div className="modal animated slideInDown">
      <ProgressBlue
        delay={300}
        variant="md" />
      <div className="experience-wrapper center-text">
        <p>
        {data.message}
        </p>
      </div>
    </div>
    <div
      onClick={onClose}
      className="backdrop" />
  </div>
)

export default ModalProcessing
