import React from 'react'
import Fade from 'react-reveal/Fade'

const ProgressBlue = ({delay,variant}) => (
  <Fade delay={delay}>
    <div className={`progress-blue progress-${variant}`}>
      <svg>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
              <g transform="translate(-163.000000, -235.000000)" stroke="#344A54" strokeWidth="2">
                  <path className="path-long" d="M188,284 C194.876574,284 201.077669,281.10793 205.453323,276.473752 C209.511893,272.175391 212,266.37826 212,260 C212,246.745166 201.254834,236 188,236 C174.745166,236 164,246.745166 164,260 C164,267.176085 167.149486,273.616567 172.141595,278.014587" transform="translate(188.000000, 260.000000) rotate(-270.000000) translate(-188.000000, -260.000000) "></path>
              </g>
          </g>
      </svg>
    </div>
  </Fade>

)

export default ProgressBlue
