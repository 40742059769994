import React from 'react'
import TimelineLabel from "../components/timelineLabel"
import TimelineDate from "../components/timelineDate"
import Map from "../components/map"

const TimelineItem = ({icon,label,date,styles,type,coordinates}) => (
  <div className={`${styles} timeline-detail`}>
      {
        icon !== undefined && label !== undefined && (
          <TimelineLabel
            icon={icon}
            label={label} />
        )
      }
      {
        date !== undefined && (
          <TimelineDate
            date={date} />
        )
      }
      {
        type === "map" && (
          <div className="timeline-map">
            <Map
              zoom={14}
              coordinates={coordinates} />
          </div>
        )
      }
  </div>
)

export default TimelineItem
