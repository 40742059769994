import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => (
  <section className="fullscreen gradient-bg vertical-align">
    <div className="experience-wrapper">
      <Link
        to="/provenance?uid=edd74117-9264-477a-b713-606420572357"
        className="nav-link"
      >
        Provenance
      </Link>
      <Link
        to="/customer-loyalty?uid=edd74117-9264-477a-b713-606420572357"
        className="nav-link"
      >
        Customer Loyalty
      </Link>
      <Link
        to="/instant-win?uid=edd74117-9264-477a-b713-606420572357"
        className="nav-link"
      >
        Instant Win
      </Link>
    </div>
  </section>
)

export default Home
