import React from 'react'
import Logo from '../../common/logo'
import Bottle from '../../common/bottle'

const ProvenanceLoading = ({data}) => (
  <>
    <section className="fillscreen gradient-bg provenance-loader">
      <Logo size="lg" classes="animated fadeIn" />
      <Bottle classes="provenance-bottle-img bottle-img-overlay animated fadeInUp" />
      <div className="intro-text-wrapper center-text text-box animated fadeIn">
        <div className="intro-text">
          <h3 className="white-text">
            {data.subtitle}
          </h3>
          <h1 className="color1-text animated fadeIn" dangerouslySetInnerHTML={{ __html: data.title }} />
          <p className="color1-text animated fadeIn">
            {data.standfirst}
          </p>
        </div>
      </div>
    </section>
  </>
)

export default ProvenanceLoading
