import React, { Component } from 'react'
import Data from '../../data/instantWin'
import Footer from "../../common/footer"
import InstantWinCheck from "../../components/instantWinCheck"
import InstantWinHeader from "../../components/instantWinHeader"
import Map from "../../components/map"

class InstantWinLose extends Component {

    state = {
      isLoaded: false
    }

    componentDidMount() {

      window.scroll(0, 0)

      setTimeout(f => {
        this.setState({
          isLoaded: true
        })
      },3000)

    }

    render() {

      const { isLoaded } = this.state

      return (
        <>
          {
            !isLoaded ? (
              <section className="fullscreen">
                <InstantWinCheck
                  data={Data.check} />
              </section>
            ) : (
              <>
                <section className="fullscreen">
                  <InstantWinHeader
                    data={Data.lose}
                    classes="instant-win-text-box lose-text-box center-text" />
                  <div className="container">
                    <div
                      className="animated fadeInUp">
                      <p
                        dangerouslySetInnerHTML={{ __html: Data.lose.text }} />
                      <div className="instant-win-map">
                        <Map
                          zoom={12}
                          coordinates={Data.lose.coordinates} />
                      </div>
                    </div>
                  </div>
                </section>
                <Footer />
              </>
            )
          }
        </>
      )

    }

}

export default InstantWinLose
