import React from 'react'
import Reveal from 'react-reveal/Reveal'
import Line from "../../components/line"
import Image from "../../components/image"
import TimelineItem from "../../components/timelineItem"

const Timeline = ({data}) => {
  return (
    data.map((fields, i) =>
      <Reveal
        effect="inview"
        key={i}>
        <section
          className="timeline-item-wrapper">
            <Line />
            <Reveal
              effect="fadeIn"
              duration={3000}>
              <div className={`timeline-item timeline-item-${fields.type}`}>
                {
                  fields.type === "block" ? (
                    <h2 className="white-text center-text">
                      {fields.title}
                    </h2>
                  ) : (
                    <h3 className="center-text">
                      {fields.title}
                    </h3>
                  )
                }
              {
                fields.image !== undefined && (
                  <Image
                    src={fields.image}
                    title={fields.label}
                    classes="timeline-image" />
                )
              }
              <div className="timeline-details">
                {
                  fields.text !== undefined && (
                    <p className={fields.styles}>
                      {fields.text}
                    </p>
                  )
                }
                {
                  fields.items !== undefined && (
                    fields.items.map((item, i) =>
                      <TimelineItem key={i} {...item} />
                    )
                  )
                }
              </div>
            </div>
          </Reveal>
        </section>
      </Reveal>
    )
  )
}

export default Timeline
