import React from 'react'
import Logo from '../../common/logo'
import Avatar from '../../components/avatar'
import ProgressBlue from '../../components/progressBlue'

const CustomerLoyaltyLoading = ({data}) => (
  <>
    <section className="fullscreen gradient-bg instant-win-loader">
      <Logo size="lg" classes="animated fadeIn" />
      <div className="center-text login-box animated fadeIn">
        <Avatar variant="lg" />
        <h1 className="color1-text animated fadeIn">
          {data.title}
        </h1>
        <h3 className="animated fadeIn" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
        <ProgressBlue
          delay={1000}
          variant="md" />
      </div>
    </section>
  </>
)

export default CustomerLoyaltyLoading
