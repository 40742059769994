import React from 'react'
import CountUp from 'react-countup'

const Points = ({showPlus,animateText,points,variant,classes}) => (
  <div className={`round-badge round-badge-${variant} ${classes}`}>
    <div className="vert-align">
      <h1 className="white-text">
        {
          showPlus && (
            <span>
              +
            </span>
          )
        }
        {
          animateText ? (
            <CountUp end={parseInt(points)} />
          ) : (
            <span>
              {points}
            </span>
          )
        }

      </h1>
      <h3 className="white-text">
        pts
      </h3>
    </div>
  </div>
)

export default Points
