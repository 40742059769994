import React, { Component } from 'react'
import Data from '../../data/customerLoyalty'
import Footer from '../../common/footer'
import CustomerLoyaltyHeader from '../../components/customerLoyaltyHeader'
import Card from '../../components/card'
import ModalRedeem from '../../components/modalRedeem'

class CustomLoyaltyExperiences extends Component {

    state = {
      isValid: false,
      isError: false,
      totalPoints: 30,
      newPoints: 0,
      code: null,
      showModal: false,
      isRedeemPoints: false
    }

    componentDidMount() {
      window.scroll(0, 0)
    }

    handleOpenModal() {
      window.scroll(0, 0)
      document.body.classList.add('no-scroll')
      this.setState({
        showModal: true
      })
    }

    handleCloseModal() {
      document.body.classList.remove('no-scroll')
      this.setState({
        showModal: false
      })
    }

    handleCodeInput = (vals) => {
      const lng = vals.length === 4 ? true : false
      if (lng) {
        this.setState({
          isValid: true,
          code: vals
        })
      }
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const { code } = this.state
      const urlParams = new URLSearchParams(window.location.search)
      const uid = urlParams.has('uid') ? urlParams.get('uid') : null

      // Check if its in the array and matches url
      const check = Data.codes.filter(f => f.uid === uid && f.code === code.toLowerCase())

      if (check.length >= 1) {

        const newPoints = check[0].points
        let totalPoints = this.props.totalPoints + newPoints

        // Update the parent scope
        this.props.onUpdateTotalPoints(totalPoints)

        // Valid code
        this.setState({
          isSubmitted: true,
          isValid: true,
          isRedeemPoints: true,
          newPoints: newPoints
        })

        setTimeout((e) => {
          this.handleCloseModal()
        },4000)

      } else {
        // Invalid code
        this.setState({
          isSubmitted: true,
          isError: true,
          code: null
        })

      }
    }

    render() {

      const {showModal,newPoints,isValid,isError,isRedeemPoints} = this.state
      const {experiences,totalPoints} = this.props
      const uid = window.location.search

      return (
        <>
          <section className="fullscreen gradient-bg">
            <CustomerLoyaltyHeader
              totalPoints={totalPoints} />
            <div className="cards container">
              <button
                onClick={this.handleOpenModal.bind(this)}
                className="btn btn-primary">
                {Data.experiences.label}
              </button>
              { experiences.map((card,i) =>
                <Card
                  key={i}
                  index={i}
                  uid={uid}
                  {...card} />
              )}
            </div>
          </section>
          <Footer />
          {
            showModal && (
              <ModalRedeem
                isRedeemPoints={isRedeemPoints}
                isValid={isValid}
                isError={isError}
                onClose={this.handleCloseModal.bind(this)}
                onChange={this.handleCodeInput.bind(this)}
                onSubmit={this.handleSubmit.bind(this)}
                newPoints={newPoints}
                data={Data.modalRedeem} />
            )
          }
        </>
      )

    }

}

export default CustomLoyaltyExperiences
