import React, { Component } from 'react'
import Data from '../../data/customerLoyalty'
import Footer from '../../common/footer'
import ModalProcessing from '../../components/modalProcessing'
import CustomerLoyaltyHeader from '../../components/customerLoyaltyHeader'
import ExperienceDetails from '../../components/experienceDetails'

class CustomLoyaltyExperience extends Component {

    state = {
      isLoaded: false,
      isError: false,
      showModal: false,
      experience: {},
    }

    componentDidMount() {
      window.scroll(0, 0)

      // Get slug from url
      const slug = window.location.pathname.split("/").pop()

      // Fetch experience from JSON doc
      const experience = this.props.experiences.filter(f => f.slug === slug)[0]

      this.setState({
        experience: experience,
        isLoaded: true
      })
    }

    handleOpenModal() {
      window.scroll(0, 0)
      document.body.classList.add('no-scroll')
      this.setState({
        showModal: true
      })

      setTimeout((e) => {
        this.handleCloseModal()
        this.handleRedeemExperience()
      },2600)

    }

    handleCloseModal() {
      document.body.classList.remove('no-scroll')
      this.setState({
        showModal: false
      })
    }


    handleRedeemExperience() {

      let totalPoints = this.props.totalPoints - this.state.experience.points

      // Update the parent scope
      this.props.onUpdateTotalPoints(totalPoints)
      this.props.onRedeemExperience(this.state.experience)

    }

    render() {

      const {isLoaded,showModal,experience} = this.state
      const {totalPoints} = this.props
      const uid = window.location.search

      return (
        <>
          <section className="fullscreen gradient-bg">
            <CustomerLoyaltyHeader
              uid={uid}
              showBackBtn={true}
              totalPoints={totalPoints} />
            {
              isLoaded && (
                <ExperienceDetails
                  label={Data.experience.label}
                  totalPoints={totalPoints}
                  onClick={this.handleOpenModal.bind(this)}
                  data={Data.redeemed}
                  {...experience} />
              )
            }
          </section>
          <Footer />
          {
            showModal && (
              <ModalProcessing
                onClose={this.handleCloseModal.bind(this)}
                data={Data.modalProcessing} />
            )
          }
        </>
      )

    }
}

export default CustomLoyaltyExperience
