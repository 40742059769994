import React from 'react'

const CardFooter = ({title,subtitle}) => (
  <div className="card-footer">
    <div className="card-inner">
      <h4 className="white-text">{title}</h4>
      <p>{subtitle}</p>
    </div>
  </div>
)

export default CardFooter
