import React from 'react'
import InstantWinHeader from "../components/instantWinHeader"
import Progress from "../components/progress"

const InstantWinCheck = ({data}) => (
  <>
  <InstantWinHeader
    data={data}
    showBottle={false}
    classes="instant-win-text-box check-text-box center-text" />
  <Progress
    delay={0}
    variant="lg" />
  </>
)

export default InstantWinCheck
