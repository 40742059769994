import React, { Component } from 'react'
import History from '../../common/history'
import Data from '../../data/customerLoyalty'
import Loading from '../../pages/customerLoyalty/loading'

class CustomLoyaltyHome extends Component {

    componentDidMount() {

      window.scroll(0, 0)

      const urlParams = new URLSearchParams(window.location.search)
      const uid = urlParams.has('uid') ? urlParams.get('uid') : null

      setTimeout(f => {
        History.push(`/customer-loyalty/experiences?uid=${uid}`)
      },2300)

    }

    render() {

      return (
        <Loading
          data={Data.loading}
        />
      )

    }

}

export default CustomLoyaltyHome
